import OneSignal from "react-onesignal";

export default async function runOneSignal() {
  await OneSignal.init({
    appId: process.env.REACT_APP_ONESIGNAL_APP_ID,
    safari_web_id: process.env.REACT_APP_ONESIGNAL_APP_ID_SAFARI,
    autoResubscribe: true,
    promptOptions: {
      actionMessage: "سجل لتصلك كل الاشعارات الجديدة",
      acceptButtonText: "سجل",
      cancelButtonText: "لاحقا",
    },
    serviceWorkerParam: { scope: "/" },
    serviceWorkerPath: "serviceWorker.js",
  });

  OneSignal.showSlidedownPrompt();
}
