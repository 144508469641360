import { createSlice } from "@reduxjs/toolkit";
// import {
//     factory
// } from "../../api/apiFactory";
// import {
//     showNotification
// } from "./notificationSlice";
// const appApi = factory.get("app");

const initialState = {
  is_drawer_opened: false,
  c_dialog: false,
  confirm_dialog: {
    open: false,
    title: "",
    message: "",
    loading: false,
    onConfirm: () => {},
    onCancel: () => {},
  },
  positions: {
    loading: false,
    data: [],
  },
  countries: {
    loading: false,
    data: [],
  },
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setDrawer: (state, action) => {
      state.is_drawer_opened = !state.is_drawer_opened;
    },
    toggleConfirmDialog: (state, { payload }) => {
      // state.c_dialog = !state.c_dialog
      state.confirm_dialog.open = !state.confirm_dialog.open;
      if (!payload) return;
      state.confirm_dialog.title = payload.title;
      state.confirm_dialog.message = payload.message;
      state.confirm_dialog.onConfirm = payload.onConfirm;
    },
    setConfirmDialogLoading: (state, action) => {
      state.confirm_dialog.loading = !state.confirm_dialog.loading;
    },
    setCountriesAndPostion: (state, action) => {
      state[action.payload.key].data = action.payload.data;
    },
  },
});

export const {
  setLoading,
  setDrawer,
  setCountriesAndPostion,
  toggleConfirmDialog,
  setConfirmDialogLoading,
} = appSlice.actions;

export default appSlice.reducer;

export const getAllCountrys = () => async (dispatch) => {
  try {
    // dispatch(setLoading('countries'));
    // const res = await appApi.allCountrys()
    // dispatch(setCountriesAndPostion({
    //     key: 'countries',
    //     data: res.data.data
    // }));
    // dispatch(setLoading('countries'));
  } catch (err) {
    // dispatch(setLoading('countries'));
    throw new Error(err);
  }
};
