import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
const expiry_customersApi = factory.get("expiry_customers");

const initialState = {
  expiry_customers: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      status_id: null,
      city_id: null,
      cabinet_id: null,
    },
    dialog: false,
  },
};

const ExpiryCustomersSlice = createSlice({
  name: "expiryCustomers",
  initialState,
  reducers: {
    setExpiryCustomers: (state, { payload }) => {
      state.expiry_customers.data = payload.data;
      state.expiry_customers.total = payload.total;
      state.expiry_customers.loading = false;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setFilters: (state, { payload }) => {
      state.expiry_customers.filters = {
        ...state.expiry_customers.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.expiry_customers.filters = initialState.expiry_customers.filters;
    },
  },
});

export const { setExpiryCustomers, setLoading, setFilters, resetFilters } =
  ExpiryCustomersSlice.actions;

export default ExpiryCustomersSlice.reducer;

//axios
export const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().expiryCustomers.expiry_customers.filters;
    const _filters = {
      ...filters,
      city_id: filters.city_id ? filters.city_id.id : null,
      cabinet_id: filters.cabinet_id ? filters.cabinet_id.id : null,
    };
    dispatch(setLoading("expiry_customers"));
    const res = await expiry_customersApi.index(_filters);
    dispatch(setExpiryCustomers(res.data));
  } catch (err) {
    dispatch(setLoading("expiry_customers"));
    throw new Error(err);
  }
};

export const getCity = () => async (dispatch, getState) => {
  try {
    const city = getState().cabinets.city;
    if (city.data.length > 0) return;
    dispatch(setLoading("city"));
    const res = await expiry_customersApi.index({ take: 30 });
    // dispatch(setCity(res.data.data));
    dispatch(setLoading("city"));
  } catch (err) {
    dispatch(setLoading("city"));
    throw new Error(err);
  }
};

export const ExpiryCustomers = {
  index,
  getCity,
};
