import {
    createSlice
} from "@reduxjs/toolkit";
import {
    factory
} from "../../api/apiFactory";

const notificationsApi = factory.get("notifications");

const initialState = {
    data: {
        show: false,
        message: "test",
        type: "success"
    },
    notifications: {
        loading: false,
        data: [],
        total: 0
    },

}

export const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setNotificationAlert: (state, {
            payload
        }) => {
            state.data.show = payload.show;
            state.data.message = payload.message;
            state.data.type = payload.type;
        },
        setLoading: (state, {
            payload
        }) => {
            state.notifications.loading = !state.notifications.loading;
        },
        setNotifications: (state, {
            payload
        }) => {
            state.notifications.data = payload.data;
            state.notifications.total = payload.total;
        }
    }
})

export const {
    setNotificationAlert,
    setLoading,
    setNotifications,
} = notificationSlice.actions

export default notificationSlice.reducer;


//alert
export const showNotification = (data) => async (dispatch) => {
    try {
        data.show = true
        dispatch(setNotificationAlert(data));
    } catch (err) {
        throw new Error(err);
    }
};
export const hideNotification = (data) => async (dispatch) => {
    try {
        dispatch(setNotificationAlert({
            message: "",
            type: data.type,
            show: false
        }));
    } catch (err) {
        throw new Error(err);
    }
};

//api 
export const getAllNotification = (data) => async (dispatch) => {
    try {
        const res = await notificationsApi.getAll(data);
        dispatch(setNotifications(res.data));
    } catch (err) {
        throw new Error(err);
    }
}
export const readNotification = (data) => async (dispatch) => {
    try {
        await notificationsApi.read({
            id: data.id,
            is_read: data.is_read
        });
        dispatch(getAllNotification());
    } catch (err) {
        throw new Error(err);
    }
}
export const deleteNotification = (id) => async (dispatch) => {
    try {
        await notificationsApi.delete(id);
        dispatch(getAllNotification());
    } catch (err) {
        throw new Error(err);
    }
}