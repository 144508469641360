import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
import dayjs from "dayjs";
const servicesStatusApi = factory.get("services_status");

const initialState = {
  services_status: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      status: null,
      error_message: "",
      start_date: null,
      end_date: null,
      service_name: "",
    },
    filters_dialog: false,
  },
  service_status_list: [
    {
      id: "in_progress",
      name: "In Progress",
      color: "warning",
    },
    {
      id: "success",
      name: "Success",
      color: "success",
    },
    {
      id: "failed",
      name: "Failed",
      color: "error",
    },
  ],
};

const servicesStatusSlice = createSlice({
  name: "servicesStatus",
  initialState,
  reducers: {
    setServicesData: (state, { payload }) => {
      state.services_status.data = payload.data;
      state.services_status.total = payload.total;
      state.services_status.loading = false;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setFilters: (state, { payload }) => {
      state.services_status.filters = {
        ...state.services_status.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.services_status.filters = initialState.services_status.filters;
    },

    setFiltersDialog: (state, { payload }) => {
      state.services_status.filters_dialog =
        !state.services_status.filters_dialog;
    },
    resetForm: (state) => {
      state.services_status.form = initialState.services_status.form;
    },
  },
});

export const {
  setServicesData,
  setLoading,
  setFilters,
  resetFilters,
  resetForm,
  setErrors,
  setFiltersDialog,
} = servicesStatusSlice.actions;

export default servicesStatusSlice.reducer;

//axios
export const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().servicesStatus.services_status.filters;
    const filters_params = {
      ...filters,
    };
    dispatch(setLoading("services_status"));
    const res = await servicesStatusApi.index(filters_params);
    dispatch(setServicesData(res.data));
  } catch (err) {
    dispatch(setLoading("services_status"));
    throw new Error(err);
  }
};

export const ServicesStatus = {
  index,
};
