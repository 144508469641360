import { createSlice } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
import { Users } from "./usersSlice";

const rolesPermissionsApi = factory.get("permissionsRoles");

const initialState = {
  roles: {
    loading: false,
    data: [],
  },
  permissions: {
    loading: false,
    data: [],
  },
  role: {
    loading: false,
    form: {
      id: "",
      name: "",
      permissions: [],
    },
    dialog: false,
    errors: {},
  },
  user_role: {
    loading: false,
    form: {
      id: "",
      role: "",
      permissions: [],
    },
    dialog: false,
    errors: {},
  },
};

export const rolesPermissionsSlice = createSlice({
  name: "rolesPermissions",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state[action.payload].loading = !state[action.payload].loading;
    },
    setRoles: (state, action) => {
      state.roles.data = action.payload.data.data;
    },
    setPermissions: (state, action) => {
      state.permissions.data = action.payload.data.data;
    },
    resetForm: (state, action) => {
      state.role.form = initialState.role.form;
    },
    setDialog: (state, { payload }) => {
      if (payload?.id) {
        state.role.form.id = payload.id;
        state.role.form.name = payload.name;
        if (payload.permissions.length > 0) {
          payload.permissions.map((item) => {
            state.role.form.permissions.push(item.name);
          });
        } else {
          state.role.form.permissions = [];
        }
      } else state.role.form = initialState.role.form;
      state.role.dialog = !state.role.dialog;
    },
    setUserRoleDialog: (state, { payload }) => {
      if (payload) {
        state.user_role.form.id = payload.id;
        state.user_role.form.role = payload.role;
        if (payload.permissions.length > 0) {
          payload.permissions.map((item) => {
            state.user_role.form.permissions.push(item.name);
          });
        } else {
          state.user_role.form.permissions = [];
        }
      } else {
        state.user_role.form = initialState.user_role.form;
      }
      state.user_role.dialog = !state.user_role.dialog;
    },
  },
});

export const {
  setLoading,
  setRoles,
  setPermissions,
  setDialog,
  resetForm,
  setUserRoleDialog,
} = rolesPermissionsSlice.actions;

export default rolesPermissionsSlice.reducer;

//axios
const index = () => async (dispatch) => {
  try {
    dispatch(setLoading("roles"));
    const res = await rolesPermissionsApi.allRoles();
    dispatch(permissions());
    dispatch(setRoles(res));
    dispatch(setLoading("roles"));
  } catch (err) {
    dispatch(setLoading("roles"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const permissions = () => async (dispatch) => {
  try {
    dispatch(setLoading("permissions"));
    const res = await rolesPermissionsApi.allPermissions();
    dispatch(setPermissions(res));
    dispatch(setLoading("permissions"));
  } catch (err) {
    dispatch(setLoading("permissions"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const create = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("role"));
    await rolesPermissionsApi.createRole(data);
    dispatch(setLoading("role"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم إضافة الصلاحية بنجاح",
      })
    );
    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("role"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const update = (data) => async (dispatch, getState) => {
  try {
    dispatch(setLoading("role"));
    await rolesPermissionsApi.updateRole(data);
    await rolesPermissionsApi.assignPermissionToRole(data);

    dispatch(setLoading("role"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم تحديث الصلاحية بنجاح",
      })
    );
    dispatch(setDialog());
    dispatch(resetForm());
    dispatch(index());
  } catch (err) {
    dispatch(setLoading("manage_client"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const assignRoleToUser = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("user_role"));
    const request = {
      user_id: data.id,
      roles: [data.role.name],
    };
    await rolesPermissionsApi.assignRoleToUser(request);
    dispatch(setLoading("user_role"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم تحديث الصلاحية بنجاح",
      })
    );
    dispatch(setUserRoleDialog());
    dispatch(resetForm());
    dispatch(Users.index());
  } catch (err) {
    // dispatch(setLoading("manage_employee"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const assignPermissionsToUser = (data) => async (dispatch) => {
  try {
    dispatch(setLoading("user_role"));
    const request = {
      user_id: data.id,
      permissions: data.permissions,
    };
    await rolesPermissionsApi.assignPermissionsToUser(request);
    dispatch(setLoading("user_role"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم تحديث الصلاحية بنجاح",
      })
    );
    dispatch(setUserRoleDialog());
    dispatch(resetForm());
    dispatch(Users.index());
  } catch (err) {
    // dispatch(setLoading("manage_employee"));
    dispatch(
      showNotification({
        type: "error",
        message: err.response.data.errors[0],
      })
    );
    throw new Error(err);
  }
};
const deleteRole = (id) => async (dispatch) => {
  try {
    dispatch(setLoading("roles"));

    await rolesPermissionsApi.deleteRole(id);
    dispatch(setLoading("roles"));
    dispatch(
      showNotification({
        type: "success",
        message: "تم حذف بنجاح",
      })
    );
    dispatch(index());
    return {
      success: true,
    };
  } catch (err) {
    dispatch(setLoading("roles"));
    dispatch(
      showNotification({
        type: "error",
        message: "حدث خطأ أثناء الحذف",
      })
    );
    throw new Error(err);
  }
};

export const RolesPermissions = {
  index,
  permissions,
  create,
  update,
  assignRoleToUser,
  assignPermissionsToUser,
  deleteRole,
};
