import { createSlice } from "@reduxjs/toolkit";
import { factory } from "../../api/apiFactory";
import { showNotification } from "./notificationSlice";
import dayjs from "dayjs";
const usersApi = factory.get("users");

const initialState = {
  users: {
    data: [],
    loading: false,
    total: 0,
    filters: {
      skip: 0,
      take: 25,
      page: 0,
      search: "",
      role_id: null,
      permission_id: null,
    },
    dialog: false,
  },
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsers: (state, { payload }) => {
      state.users.data = payload.data;
      state.users.total = payload.total;
      state.users.loading = false;
    },
    setLoading: (state, { payload }) => {
      state[payload].loading = !state[payload].loading;
    },
    setFilters: (state, { payload }) => {
      state.users.filters = {
        ...state.users.filters,
        ...payload,
      };
    },
    resetFilters: (state) => {
      state.users.filters = initialState.users.filters;
    },

    setFiltersDialog: (state, { payload }) => {
      state.subscriptions.dialog = !state.subscriptions.dialog;
    },
    resetForm: (state) => {
      state.subscription.form = initialState.subscription.form;
    },
    setErrors: (state, { payload }) => {
      state.subscription.errors = payload;
    },
    setSubscriberHistory: (state, { payload }) => {
      state.subscribers_history.data = payload.data;
      state.subscribers_history.loading = false;
    },
  },
});

export const {
  setUsers,
  setLoading,
  setFilters,
  resetFilters,
  resetForm,
  setErrors,
  setFiltersDialog,
  setSubscriberHistory,
} = usersSlice.actions;

export default usersSlice.reducer;

//axios
export const index = () => async (dispatch, getState) => {
  try {
    const filters = getState().users.users.filters;
    const filters_params = {
      ...filters,
      role_id: filters.role_id?.id || null,
      permission_id: filters.permission_id?.id || null,
    };
    dispatch(setLoading("users"));
    const res = await usersApi.index(filters_params);
    dispatch(setUsers(res.data));
  } catch (err) {
    dispatch(setLoading("users"));
    throw new Error(err);
  }
};

export const Users = {
  index,
};
