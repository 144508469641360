import axios from "axios";
import Cookies from "js-cookie";

const authInterceptor = (config) => {
  const token = Cookies.get("halasat-ftth-client");
  config.headers["Authorization"] = `Bearer ${token}`;
  config.headers["Content-Type"] = "application/json";
  config.headers["app-version"] = "1.2";
  return config;
};

const api = axios.create({
  baseURL: process.env.REACT_APP_API + "/api/v1",
});

api.interceptors.request.use(authInterceptor);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // eslint-disable-next-line default-case
    switch (error.response.status) {
      case 401:
        window.location.href = "/login";
        break;
    }
    return Promise.reject(error);
  }
);
export default api;
